import Api from '@/services/Index';

const getAll = async (config) => {
    Api.defaults.baseURL = process.env.VUE_APP_ENDPOINT_STUDENT;
    return Api.get('/elective/courses', config);
}

const store = async (formData) => {
    Api.defaults.baseURL = process.env.VUE_APP_ENDPOINT_STUDENT;
    return Api.post('/elective/courses', formData);
}

const remove = async (poolId, courseId) => {
    Api.defaults.baseURL = process.env.VUE_APP_ENDPOINT_STUDENT;
    return Api.delete('/elective/courses/' + poolId + '/' + courseId);
}

const downloadAsExcel = (config)=>{
    Api.defaults.baseURL = process.env.VUE_APP_ENDPOINT_STUDENT;
    return Api.post('/elective/courses/excel-import',config)
}

export default {
    getAll,
    store,
    remove,
    downloadAsExcel
}
